import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { Dialog, Box, Paper } from '@mui/material';
//
import { varFade } from './variants';
export default function DialogAnimate({ open = false, variants, onClose, children, sx, ...other }) {
    return (_jsx(AnimatePresence, { children: open && (_jsx(Dialog, { fullWidth: true, maxWidth: "xs", open: open, onClose: onClose, PaperComponent: (props) => (_jsxs(Box, { component: m.div, ...(variants ||
                    varFade({
                        distance: 120,
                        durationIn: 0.32,
                        durationOut: 0.24,
                        easeIn: 'easeInOut',
                    }).inUp), sx: {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }, children: [_jsx(Box, { onClick: onClose, sx: { width: '100%', height: '100%', position: 'fixed' } }), _jsx(Paper, { sx: sx, ...props, children: props.children })] })), ...other, children: children })) }));
}
